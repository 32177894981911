import React, { useState, useEffect } from "react"
import { useQuery, useMutation, useApolloClient } from "@apollo/react-hooks"
import { gql } from "apollo-boost"
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  Container,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  TabContent,
  TabPane,
  Label,
  Button,
  Input,
  Form,
  FormGroup,
} from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import ProfileMenu from "../components/VerticalLayout/ProfileMenu"

import { Link, navigate } from "gatsby"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumb"
const GET_PROJECTS = gql`
  query {
    project {
      id
      project_name
    }
  }
`

const ADD_PROJECT = gql`
  mutation insert_project($project_name: String!, $user_id: Int!) {
    insert_project(
      objects: {
        project_name: $project_name
        project_title: $project_name
        user_id: $user_id
      }
    ) {
      returning {
        id
        project_name
        user_id
      }
    }
  }
`

const Projects = () => {
  const [modal, setModal] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const { loading, error, data } = useQuery(GET_PROJECTS)

  const togglemodal = () => {
    setModal(!modal)
  }


  //

  const [
    insert_project,
    { loading: addProjectLoading, error: addProjectError },
  ] = useMutation(ADD_PROJECT, {
    update: (cache, { data }) => {
      setModal(false)
      const existingProjects = cache.readQuery({
        query: GET_PROJECTS,
      })

      // Add the new author to the cache
      const newProject = data.insert_project.returning[0]
      cache.writeQuery({
        query: GET_PROJECTS,
        data: { project: [newProject, ...existingProjects.project] },
      })
    },
  })

  if (addProjectLoading) return "loading..."
  if (addProjectError) return `error: ${addProjectError.message}`

  const handleSubmit = (event, values) => {
    insert_project({
      variables: {
        project_name: values.project_name,
        user_id: 1,
      },
    })
  }

  const handleNavigate = (project) =>{localStorage.setItem(
                                        "selected_project",
                                        JSON.stringify(project)
                                      )
                                      navigate("/dashboard")}
  

  //

  if (loading)
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>
    )
  if (error) return `error: ${error.message}`

  return (
    <React.Fragment>
      <SEO />
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex justify-content-between w-100">
            <Link to="/" className="logo mx-5">
              <span className="logo-sm">
                <img src="/images/paraffin-logo.png" alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src="/images/paraffin-logo.png" alt="" height="50" />
              </span>
            </Link>
            <div>
              <Link to="#" className="my-auto mx-3">
                <i className="bx bx-support font-size-16 align-middle mr-1 text-danger"></i>
                <span>Support</span>
              </Link>
              <ProfileMenu />
            </div>
          </div>
        </div>
      </header>
      <div style={{ width: "75vw" }} className="mx-auto page-content">
        <Container fluid>
          <Breadcrumbs title="Projects" breadcrumbItem="Projects" />

          <div style={{ marginTop: "20px" }}>
            {data.project.length == 0 && (
              <Row className="justify-content-center mt-lg-5">
                <Col xl="5" sm="8">
                  <Card>
                    <CardBody>
                      <div className="text-center">
                        <Row className="justify-content-center">
                          <Col lg="10">
                            <h4 className="mt-4 font-weight-semibold">
                              Add New Project
                            </h4>
                            <p className="text-muted mt-3">
                              You have no project. Add your first project
                            </p>

                            <div className="mt-4">
                              {/* button triggers modal */}
                              <Button
                                type="button"
                                color="primary"
                                onClick={togglemodal}
                              >
                                Click here to add project
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        <Row className="justify-content-center mt-5 mb-2">
                          <Col sm="6" xs="8">
                            <div>
                              <img
                                src="/images/verification-img.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {data.project.length > 0 && (
              <Row>
                <Col lg={4}>
                  <Card body className="text-center">
                    <CardTitle className="mt-0">Add New Project</CardTitle>
                    <div className="d-flex justify-content-center">
                      <Button
                        type="button"
                        color="primary"
                        className="waves-effect waves-light w-50"
                        onClick={togglemodal}
                      >
                        {" "}
                        <i className="bx bx-plus font-size-16 align-middle mr-2 "></i>{" "}
                        Add
                      </Button>
                    </div>
                  </Card>
                </Col>
                {data.project.map((project, index) => (
                  <Col lg={4} key={index}>
                      <Card body className="text-center" onClick={()=>handleNavigate({label:project.project_name, value:project.id})} style={{cursor:"pointer"}}>
                        <CardText className="mt-0">
                          ParaffinIoT.io Projects
                        </CardText>
                        <CardTitle className="mt-0">
                          {project.project_name}
                        </CardTitle>
                        <div className="d-flex justify-content-center"></div>
                      </Card>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </Container>
      </div>
      {/* modal */}
      <Modal
        isOpen={modal}
        role="dialog"
        size="md"
        autoFocus={true}
        // centered={true}
        id="addProjectModal"
        tabIndex="-1"
        toggle={togglemodal}
        style={{ marginTop: "15vh" }}
      >
        <div className="modal-content">
          <ModalHeader toggle={togglemodal}>Add New Project</ModalHeader>
          <ModalBody>
            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <TabContent
                activeTab={activeTab}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={1} id="personal-info">
                  <AvForm onValidSubmit={handleSubmit}>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          {/* <Label for="kycfirstname-input">Project Name</Label> */}
                          <AvField
                            name="project_name"
                            label="Project Name"
                            className="form-control"
                            placeholder="Enter Project Name"
                            // type="email"
                            required
                          />
                        </FormGroup>
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            <i className="bx bx-plus mr-2"></i> Add
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </TabPane>
                <TabPane tabId={2} id="confirm-email">
                  <div>
                    <Form>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <Label for="kycemail-input">Email</Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycemail-input"
                              placeholder="Enter Email Address"
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="kycconfirmcode-input">
                              Confirm code
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              id="kycconfirmcode-input"
                              placeholder="Enter Confirm code"
                            />
                          </FormGroup>

                          <div className="mb-3">
                            Didn't recieve code ?
                            <Button type="button" color="link">
                              Resend Code
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Projects
export { GET_PROJECTS }

// export default function Home() {
//   return <div>Hello world!</div>
// }
