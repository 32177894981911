
import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Link} from "gatsby"

//i18n

// users



  export default function ProfileMenu(params) {
      const [menu, setMenu] = useState(false)
      const [username, setUserName] = useState("Hadi Madhavi")

      const toggle = ()=>setMenu(!menu)
      
        return (
            <React.Fragment>

                <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src="/images/users/hadi.jpeg" alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-2 mr-1">{username}</span>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" href="#">
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                           Profile
                        </DropdownItem>
                        <DropdownItem tag="a" href="#">
                            <span className="badge badge-success float-right mt-1">5</span><i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                           Settings
                        </DropdownItem>
                        <div className="dropdown-divider"></div>
                        <Link to="/logout" className="dropdown-item">
                            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                            <span>Logout</span>
                        </Link>
                    </DropdownMenu>
                </Dropdown>

            </React.Fragment>
        );
    }
